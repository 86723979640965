<template>
  <div v-if="building" class="tw-m-2">
    <h1 class="tw-text-lg tw-font-bold tw-mb-3">{{ building.name }}</h1>
    <div class="tw-mb-4">
      <button
        :class="{
          'tw-bg-blue-500 tw-text-white': viewMode === 'sales',
          'tw-bg-gray-200 tw-text-black': viewMode !== 'sales',
        }"
        class="tw-p-2 tw-rounded-l"
        @click="viewMode = 'sales'"
      >
        Sales
      </button>
      <button
        :class="{
          'tw-bg-blue-500 tw-text-white': viewMode === 'inspection',
          'tw-bg-gray-200 tw-text-black': viewMode !== 'inspection',
        }"
        class="tw-p-2"
        @click="viewMode = 'inspection'"
      >
        Inspection
      </button>
      <button
        :class="{
          'tw-bg-blue-500 tw-text-white': viewMode === 'installation',
          'tw-bg-gray-200 tw-text-black': viewMode !== 'installation',
        }"
        class="tw-p-2 tw-rounded-r"
        @click="viewMode = 'installation'"
      >
        Installation
      </button>
    </div>

    <table class="tw-table-auto tw-border tw-border-collapse tw-border-slate-400">
      <thead>
        <tr>
          <td
            v-for="(part, index) in parts"
            :colspan="part.columns?.length * 2"
            class="tw-border tw-p-2 tw-w-16 tw-align-middle"
            :class="{
              'tw-bg-green-100': getApprovedSales(part) < part.limit,
              'tw-bg-yellow-100': getApprovedSales(part) === part.limit,
              'tw-bg-red-100': getApprovedSales(part) > part.limit,
            }"
          >
            <template v-if="edit">
              {{ partName(index) }}
            </template>
            <template v-else>
              max.
              {{ part.limit }}
            </template>
          </td>
        </tr>
        <tr>
          <template v-for="(column, columnIndex) in layout">
            <th class="tw-border tw-p-2 tw-w-16 tw-align-middle">
              <button
                v-if="edit && column.length === 0"
                @click="removeColumn(columnIndex)"
                class="tw-bg-red-200 tw-rounded tw-p-1 hover:tw-bg-red-100 tw-text-red-600"
              >
                <TrashIcon class="tw-h-4 tw-h-4" />
              </button>
              <button
                v-else-if="edit"
                class="tw-bg-green-200 tw-rounded tw-p-1 hover:tw-bg-green-100 tw-text-green-600"
                @click="addColumn(columnIndex)"
              >
                <PlusIcon class="tw-h-4 tw-h-4" />
              </button>
            </th>
            <th class="tw-border tw-p-2 tw-w-16">Status</th>
          </template>
        </tr>
      </thead>
      <tbody>
        <tr v-for="floor in floors">
          <template v-for="loc in floor">
            <template v-if="!loc">
              <td class="tw-border tw-p-2 tw-w-16"></td>
              <td class="tw-border tw-p-2 tw-w-16"></td>
            </template>
            <template v-else>
              <td
                class="tw-border tw-p-2 tw-w-16"
                :class="{ 'tw-bg-red-200': selected === loc?.location_id }"
              >
                <div :title="`${loc.address.street} ${loc.address.house_number}`">
                  {{ loc.address.house_number }}
                </div>
              </td>
              <td
                v-if="edit"
                class="tw-border tw-p-2 tw-w-16"
                :class="{ 'tw-bg-red-500': selected === loc?.location_id }"
              >
                <button
                  v-if="selected !== loc.location_id"
                  class="tw-text-xs tw-bg-blue-100 tw-p-1 tw-rounded"
                  @click="selected = loc.location_id"
                >
                  Selecteer
                </button>
              </td>
              <td
                v-else
                class="tw-border tw-p-2 tw-w-16 tw-text-xs tw-align-middle"
                :class="statusToColorClass(loc.status[viewMode]?.status)"
              >
                <div :title="formatISO(loc.status[viewMode]?.status_at)" v-if="loc.status">
                  {{ loc.status[viewMode]?.status }}
                </div>
              </td>
            </template>
          </template>
        </tr>
        <tr v-if="edit">
          <td
            colspan="2"
            class="tw-border tw-p-2 tw-w-16 tw-align-middle"
            v-for="(_, columnIndex) in layout"
          >
            {{ parts?.findIndex((part) => part.columns.includes(columnIndex)) }}
            <FormField
              hide-label="true"
              :label="`Column ${columnIndex}`"
              type="select"
              :value="parts?.findIndex((part) => part.columns.includes(columnIndex))"
              @input="updatePart(Number($event), columnIndex)"
              :options="partOptions"
            />
          </td>
        </tr>
      </tbody>
    </table>

    <table v-if="edit">
      <thead>
        <th class="tw-w-32 tw-p-1"></th>
        <th class="tw-w-64 tw-p-1">Maximaal aantal aanmeldingen</th>
      </thead>
      <tbody>
        <tr v-for="(part, index) in parts">
          <td>{{ partName(index) }}</td>
          <td>
            <FormField
              :label="`Limiet ${index}`"
              hide-label="true"
              type="number"
              :value="part.limit"
              @input="(e) => updatePartLimit(index, Number(e))"
            />
          </td>
        </tr>
      </tbody>
    </table>

    <div v-if="edit" class="tw-flex tw-my-2 tw-gap-2">
      <button @click="switchRows" class="tw-bg-gray-400 hover:tw-bg-gray-300 tw-rounded tw-p-1">
        Keer de verdiepingen om
      </button>
      <button @click="switchColumns" class="tw-bg-gray-400 hover:tw-bg-gray-300 tw-rounded tw-p-1">
        Keer de kolommen om
      </button>
    </div>

    <div class="tw-mt-3" v-if="edit">
      Klik op selecteer en gebruik de pijltjestoetsen op je toetsenbord om de huisnummers te
      verplaatsen
    </div>

    <div class="tw-mt-3">
      <button
        class="tw-bg-blue-400 hover:tw-bg-blue-300 tw-rounded tw-p-1"
        @click="edit = true"
        v-if="!edit"
      >
        Bewerken
      </button>
      <button
        v-else
        class="tw-bg-green-400 hover:tw-bg-green-300 tw-rounded tw-p-1"
        @click="saveLayout"
      >
        Opslaan
      </button>
      <button
        v-if="edit"
        class="tw-bg-red-400 hover:tw-bg-green-300 tw-rounded tw-p-1 tw-ml-2"
        @click="edit = false"
      >
        Sluiten
      </button>
    </div>
    <div class="tw-flex tw-gap-5">
      <info-container title="Stats">
        <div v-if="summary" class="tw-mt-3">
          <table>
            <thead>
              <tr>
                <th>Status</th>
                <th>Aantal</th>
              </tr>
            </thead>
            <tbody class="tw-mt-3">
              <tr v-show="viewMode === 'installation'">
                <td class="tw-w-32 tw-p-1" :class="statuses.created">created</td>
                <td>{{ summary.created }} / {{ summary.createdPercentage }}%</td>
              </tr>
              <tr>
                <td class="tw-w-32 tw-p-1" :class="statuses.approved">approved</td>
                <td>{{ summary.approved }} / {{ summary.approvedPercentage }}%</td>
              </tr>
              <tr>
                <td class="tw-w-32 tw-p-1" :class="statuses.assigned">assigned</td>
                <td>{{ summary.assigned }} / {{ summary.assignedPercentage }}%</td>
              </tr>
              <tr>
                <td class="tw-w-32 tw-p-1" :class="statuses.cancelled">cancelled</td>
                <td>{{ summary.cancelled }} / {{ summary.cancelledPercentage }}%</td>
              </tr>

              <tr>
                <td class="tw-w-32 tw-p-1">Totaal</td>
                <td>{{ summary.total }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </info-container>
      <info-container title="Internal note">
        <div class="tw-flex tw-flex-col">
          <textarea
            class="tw-mt-2 tw-p-2 tw-border tw-border-gray-200 tw-w-[400px]"
            rows="3"
            :disabled="isLoading"
            v-model="internalNote"
          ></textarea>

          <button
            class="tw-bg-gray-500 tw-rounded tw-p-1 tw-text-white tw-mt-5"
            @click="saveLayout(internalNote)"
          >
            Update info
          </button>
        </div>
      </info-container>
    </div>
  </div>
</template>

<script setup>
import { useMutation, useQuery, useQueryClient } from '@tanstack/vue-query'
import { useRoute } from 'vue-router/composables'
import notifications from '@/util/Notifications'
import { computed, ref, watch } from 'vue'
import { onKeyStroke } from '@vueuse/core'
import { parseISO, format } from 'date-fns'
import { TrashIcon, PlusIcon } from '@vue-hero-icons/outline'
import FormField from '@/components/shared/FormField.vue'
import InfoContainer from '@/components/shared/InfoContainer.vue'

function formatISO(t) {
  if (!t) return ''
  return format(parseISO(t), 'dd-MM-yyyy HH:mm')
}

const queryClient = useQueryClient()
const route = useRoute()

const edit = ref(false)
const selected = ref(null)
const updatedLayout = ref(null)
const updatedParts = ref(null)
const viewMode = ref('sales')

const layout = computed(() => updatedLayout.value || building.value?.layout)
const parts = computed(() => updatedParts.value || building.value?.parts)

watch(
  () => edit.value,
  (value) => {
    if (!value) {
      selected.value = null
    } else {
      if (!parts.value?.length) {
        updatedParts.value = [
          {
            columns: layout.value.map((_, index) => index),
            limit: null,
          },
        ]
      }
    }
  }
)

const { data: building } = useQuery({
  queryKey: ['building', route.params.id],
  queryFn: async () => {
    const result = await fetch(
      `/tickets/api/v1/buildings/${route.params.id}?include_locations=true`
    )
    if (!result.ok) {
      notifications.addNotification({
        message: `Could not fetch building ${result.statusText}`,
        type: 'danger',
      })
      throw Exception('Not fetched')
    }
    return (await result.json()).data
  },
})

const internalNote = ref(building?.value?.note)

watch(
  () => building?.value?.note,
  () => {
    internalNote.value = building?.value?.note
  }
)

const floors = computed(() => {
  if (!building.value) return []

  const layout = updatedLayout.value || building.value.layout
  const height = Math.max(...layout.map((column) => column.length))

  let floors = []
  for (let i = 0; i < height; i++) {
    if (!floors[i]) floors[i] = []

    for (let column in layout) {
      if (!layout[column][i]) {
        floors[i].push(null)
        continue
      }

      const loc = building.value.locations.find(
        (location) => location.location_id === layout[column][i]
      )
      floors[i].push(loc)
    }
  }

  return floors
})

const statuses = {
  created: 'tw-bg-yellow-100 tw-text-yellow-600',
  approved: 'tw-bg-green-100 tw-text-green-600',
  cancelled: 'tw-bg-red-100 tw-text-red-600',
  assigned: 'tw-bg-blue-100 tw-text-blue-600',
}

function statusToColorClass(status) {
  if (!status) return 'tw-bg-gray-200'
  return statuses[status]
}

onKeyStroke('ArrowLeft', () => move(selected.value, -1, 0))
onKeyStroke('ArrowRight', () => move(selected.value, 1, 0))
onKeyStroke('ArrowDown', () => move(selected.value, 0, 1))
onKeyStroke('ArrowUp', () => move(selected.value, 0, -1))

function move(locationId, deltaColumn, deltaRow) {
  if (!locationId) return

  const layout = updatedLayout.value || building.value.layout
  let copyOfLayout = [...layout.map((column) => [...column])]

  let currentColumnIndex = layout.findIndex((column) => column.includes(locationId))
  let currentRowIndex = layout[currentColumnIndex].findIndex((location) => location === locationId)

  let columnIndex = currentColumnIndex + deltaColumn
  let rowIndex = currentRowIndex + deltaRow

  if (columnIndex < 0) {
    copyOfLayout.unshift([])
    currentColumnIndex += 1
    columnIndex = 0
  }
  if (columnIndex > copyOfLayout.length - 1) {
    copyOfLayout.push([])
  }
  if (rowIndex < 0) {
    for (let i = 0; i < copyOfLayout.length; i++) {
      copyOfLayout[i].unshift(null)
    }
    currentRowIndex += 1
    rowIndex = 0
  }
  if (rowIndex > copyOfLayout[columnIndex].length - 1) {
    for (let i = 0; i < copyOfLayout.length; i++) {
      copyOfLayout[i].push(null)
    }
  }

  if (copyOfLayout[columnIndex][rowIndex] && !deltaColumn) {
    // movement in the same column: switch locations position
    const locationToMove = copyOfLayout[columnIndex][rowIndex]
    copyOfLayout[columnIndex][rowIndex] = locationId
    copyOfLayout[currentColumnIndex][currentRowIndex] = locationToMove
  } else if (copyOfLayout[columnIndex][rowIndex]) {
    // movement between columns: remove from current position
    copyOfLayout[currentColumnIndex][currentRowIndex] = null

    // movement between columns: put in an empty spot
    if (copyOfLayout[columnIndex].includes(null)) {
      const emptyIndex = copyOfLayout[columnIndex].findIndex((location) => !location)
      copyOfLayout[columnIndex][emptyIndex] = locationId
    }
    // or: add it to the bottom
    else {
      copyOfLayout[columnIndex].push(locationId)
    }
  } else {
    copyOfLayout[columnIndex][rowIndex] = locationId
    copyOfLayout[currentColumnIndex][currentRowIndex] = null
  }

  // cleanup nulls at the end of every column
  for (let column of copyOfLayout) {
    while (column[column.length - 1] === null) {
      column.pop()
    }
  }
  // remove empty column beginning
  if (copyOfLayout[0].length === 0) {
    copyOfLayout.shift()
  }
  // remove empty column end
  if (copyOfLayout[copyOfLayout.length - 1].length === 0) {
    copyOfLayout.pop()
  }
  // remove empty row beginning
  if (copyOfLayout.every((column) => !column[0])) {
    copyOfLayout.forEach((column) => column.shift())
  }
  // remove empty row end
  if (copyOfLayout.every((column) => !column[column.length - 1])) {
    copyOfLayout.forEach((column) => column.pop())
  }

  updatedLayout.value = copyOfLayout
  return copyOfLayout
}

const { mutate: saveLayout, isLoading } = useMutation({
  mutationFn: async () => {
    const response = await fetch(`/tickets/api/v1/buildings/${route.params.id}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        layout: updatedLayout.value,
        parts: updatedParts.value,
        internal_note: internalNote.value,
      }),
    })

    if (!response.ok) {
      notifications.addNotification({
        message: `Could not save: ${response.statusText}`,
        type: 'danger',
      })
      throw new Error('Not saved')
    }

    notifications.addNotification({
      message: 'Building is updated',
      type: 'success',
    })

    const data = await response.json()
    queryClient.setQueryData(['building', route.params.id], (current) => ({
      ...current,
      ...data.data,
    }))
    edit.value = false
    updatedLayout.value = null
    updatedParts.value = null
  },
})

const summary = computed(() => {
  if (!building.value) return null

  const summary = {
    total: building.value.locations.length,
    approved: building.value.locations.filter(
      (loc) => loc.status[viewMode.value].status === 'approved'
    ).length,
    assigned: building.value.locations.filter(
      (loc) => loc.status[viewMode.value].status === 'assigned'
    ).length,
    cancelled: building.value.locations.filter(
      (loc) => loc.status[viewMode.value].status === 'cancelled'
    ).length,
    created: building.value.locations.filter(
      (loc) => loc.status[viewMode.value]?.status === 'created'
    ).length,
  }

  summary.approvedPercentage = Math.round((summary.approved / summary.total) * 100)
  summary.assignedPercentage = Math.round((summary.assigned / summary.total) * 100)
  summary.cancelledPercentage = Math.round((summary.cancelled / summary.total) * 100)
  summary.createdPercentage = Math.round((summary.created / summary.total) * 100)

  return summary
})

function switchRows() {
  updatedLayout.value = layout.value.map((column) => column.toReversed())
}
function switchColumns() {
  updatedLayout.value = layout.value.toReversed()
}
function removeColumn(columnIndex) {
  updatedLayout.value = layout.value.filter((_, index) => index !== columnIndex)
}
function addColumn(columnIndex) {
  const copy = [...layout.value]
  copy.splice(columnIndex, 0, [])
  updatedLayout.value = copy
}

const letters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'.split('')
function partName(part) {
  return `Deel ${letters[part]}`
}

const partOptions = computed(() => {
  const max = parts.value.length + 1
  return Array.from({ length: max }, (_, i) => ({
    label: partName(i),
    value: i,
  }))
})

function updatePart(partIndex, columnIndex) {
  const copy = parts.value.map((part) => ({
    ...part,
    columns: part.columns.filter((column) => column !== columnIndex),
  }))
  if (!copy[partIndex]) {
    copy.push({
      columns: [columnIndex],
      limit: null,
    })
  } else {
    copy[partIndex].columns.push(columnIndex)
  }

  updatedParts.value = copy
}

function updatePartLimit(partIndex, limit) {
  if (!updatedParts.value) {
    updatedParts.value = [...parts.value]
  }
  if (updatedParts.value && updatedParts.value.length > 0) {
    const lastPart = updatedParts.value[updatedParts.value.length - 1]
    if ((lastPart.limit === 0 || lastPart.limit === null) && lastPart.columns.length === 0) {
      updatedParts.value.pop()
    }
  }
  updatedParts.value[partIndex] = {
    ...updatedParts.value[partIndex],
    limit,
  }
}

function getApprovedSales(part) {
  if (!building.value || !part.columns) return 0

  let approvedSales = 0

  part.columns.forEach((columnIndex) => {
    const locations = layout.value[columnIndex]

    if (locations) {
      locations.forEach((locId) => {
        const loc = building.value.locations.find((location) => location.location_id === locId)
        if (loc && loc.status[viewMode.value]?.status === 'approved') {
          approvedSales++
        }
      })
    }
  })

  return approvedSales
}
</script>
