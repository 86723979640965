<template>
  <generic-modal
    @close="closeModal"
    @submit="createCase"
    title="Nieuwe case aanmaken"
    :disabled="!valid"
    width="'600px'"
  >
    <form-field
      v-if="!alarmId"
      label="Adres"
      placeholder="Zoek naar een adres"
      :options="addresses"
      type="multi"
      v-model="form.locationId"
      @search-change="triggerSearch"
      class="tw-w-[250px] tw-mr-5"
    />
    <div v-else>Adres: {{ locationId }}</div>

    <template v-if="locationId">
      <div v-if="existingCasesLoading" class="tw-text-gray-400">Bestaande cases checken...</div>
      <div v-else-if="existingCases === null" class="tw-text-red-500">
        Fout bij het zoeken naar bestaande cases
      </div>
      <div v-else-if="existingCases.length > 0" class="tw-text-red-500">
        Er bestaat al een case voor dit adres:
        <router-link
          :to="{ name: 'case-detail', params: { id: existingCases[0].id } }"
          class="tw-text-blue-400"
          >bekijk case</router-link
        >
      </div>
      <div v-else class="tw-text-green-500">Geen bestaande cases gevonden</div>
    </template>

    <form-field
      v-if="!route.query.alarmId"
      label="Categorie"
      :options="categories"
      type="select"
      v-model="form.category"
      :nullable="false"
      null-option="Select category"
    />
    <form-field type="textarea" label="Case Description" rows="3" v-model="form.description" />
  </generic-modal>
</template>

<script setup>
import { ref, onMounted, computed, watch, reactive } from 'vue'
import { useRoute, useRouter } from 'vue-router/composables'
import { useMutation, useQuery, useQueryClient } from '@tanstack/vue-query'
import { notifications } from '@/util'
import fetchData from '@/util/fetch'
import FormField from '@/components/shared/FormField.vue'
import GenericModal from '@/components/shared/GenericModal.vue'
import { ca } from 'date-fns/locale'

const route = useRoute()
const router = useRouter()
const queryClient = useQueryClient()

const emit = defineEmits(['close'])

const props = defineProps({
  locationId: {
    type: String,
    required: false,
  },
  alarmId: {
    type: Number,
    required: false,
  },
  description: {
    type: String,
    required: false,
    default: '',
  },
})

const categories = ['Alarmbellen', 'O&M', 'Klantenservice', 'KAM']

const form = reactive({
  description: props.description,
  locationId: '',
  category: categories[0],
})

watch(
  () => props.description,
  (newVal) => {
    console.log('desc', props.description)
    form.description = newVal
  }
)

function routeToCase(id) {
  router.push({ name: 'case-detail', params: { id } })
}

const locationId = computed(() => form.locationId || props.locationId)
const query = ref('')

const triggerSearch = async (q) => {
  query.value = q
}

const { mutate: createCase } = useMutation({
  mutationFn: () => {
    const payload = {
      location_id: locationId.value,
      description: form.description,
      category: form.category,
      alarm_id: props.alarmId,
    }
    return fetchData(`/tickets/api/v1/cases`, {
      method: 'POST',
      body: JSON.stringify(payload),
    })
  },
  onError: (e) => {
    console.error('Error on creating case', e)
    const info = e.message || e.detail || e
    notifications.addNotification({
      message: `Error occured, ${info}`,
      type: 'danger',
    })
  },
  onSuccess: (data) => {
    form.description = ''
    queryClient.invalidateQueries({ queryKey: ['cases'] })

    emit('created', data.data)
  },
})

const addressesQuery = useQuery({
  queryKey: computed(() => ['addresses', query.value]),
  queryFn: async () => {
    if (!query.value || query.value.length < 1) {
      return []
    }
    try {
      const urlParams = new URLSearchParams({
        query: query.value,
      })
      const result = await fetchData(`/tickets/api/v1/locations?${urlParams}`)
      const data = await result.data
      return data.map((location) => ({
        value: location.location_id,
        label: location.legacy_address,
      }))
    } catch (error) {
      console.error('Error fetching addresses:', error)
      throw error
    }
  },
  refetchOnWindowFocus: false,
})

const addresses = computed(() => addressesQuery.data.value)

const { data: existingCases, isLoading: existingCasesLoading } = useQuery({
  queryKey: computed(() => {
    return ['openCase', locationId.value]
  }),
  queryFn: async () => {
    try {
      const urlParams = new URLSearchParams({
        location_id: locationId.value,
      })
      urlParams.append('status', 'open')
      urlParams.append('status', 'created')
      urlParams.append('status', 'muted')

      const result = await fetchData(`/tickets/api/v1/cases?${urlParams}`)

      if (result.status === 'OK') {
        return result.data
      }
      return null
    } catch (error) {
      notifications.addNotification({
        message: `Checking open cases is broken. You cannot create a new case now.`,
        type: 'danger',
      })
      return null
    }
  },
  refetchOnWindowFocus: false,
  keepPreviousData: true,
  enabled: computed(() => !!locationId.value),
})

function closeModal() {
  form.locationId = ''
  emit('close')
}

const valid = computed(() => {
  if (!locationId.value || !form.description) return false
  return existingCases.value && existingCases.value.length === 0
})
</script>
