<template>
  <div class="tw-bg-white tw-drop-shadow-xl tw-rounded-lg tw-p-5 tw-mb-5">
    <div>
      <div v-if="!meterLoading">
        <div class="tw-flex tw-justify-between tw-items-center">
          <div class="tw-flex tw-items-center">
            <span class="tw-font-semibold tw-text-gray-700">
              {{ serialNumber }} {{ meterDetail?.status ? meterDetail?.status : '' }}
            </span>
            <div
              class="tw-w-4 tw-h-4 tw-ml-3 tw-rounded-full"
              :class="{
                'tw-bg-gray-500': isFetching || isLoading,
                'tw-bg-red-500': !isLoading && !isFetching && isAlive === false,
                'tw-bg-green-500': !isLoading && !isFetching && isAlive === true,
              }"
            ></div>
            <button
              class="tw-bg-blue-500 hover:tw-bg-blue-400 tw-text-white tw-rounded tw-py-1 tw-px-2 tw-font-semibold tw-ml-5 disabled:tw-opacity-50 disabled:tw-cursor-not-allowed"
              @click="refetch"
              :disabled="isFetching"
            >
              <span v-if="isFetching">Checking...</span>
              <span v-else>Ping Meter</span>
            </button>
            <button
              class="tw-bg-blue-500 hover:tw-bg-blue-400 tw-text-white tw-rounded tw-py-1 tw-px-2 tw-font-semibold tw-ml-5 disabled:tw-opacity-50 disabled:tw-cursor-not-allowed"
              @click="pollMeter"
              :disabled="isPolling"
            >
              <span>Poll Meter</span>
            </button>
            <button
              v-if="meterDetail.breaker_status && meterDetail.breaker_status !== 'N/A'"
              class="tw-bg-blue-500 hover:tw-bg-blue-400 tw-text-white tw-rounded tw-py-1 tw-px-2 tw-font-semibold tw-ml-5 disabled:tw-opacity-50 disabled:tw-cursor-not-allowed"
              @click="toggleBreaker"
              :disabled="isActivationInProgress"
            >
              <span v-if="meterDetail.breaker_status === 'On'">Deactivate breaker</span>
              <span v-if="meterDetail.breaker_status === 'Off'">Activate breaker</span>
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="tw-flex tw-justify-between tw-items-center">
      <div>
        <div
          v-for="(chunk, index) in receivedChunks"
          :key="index"
          class="tw-mt-2 tw-border-b tw-pb-2"
        >
          <div v-if="chunk.chunk_status_code === 200" class="tw-text-green-600">
            {{ chunk.chunk_text }}
          </div>

          <div v-if="chunk.chunk_data?.rssi_value" class="tw-text-gray-600 tw-text-sm">
            <span class="tw-font-bold">RSSI value during manual poll:</span>
            {{ chunk.chunk_data.rssi_value }}
            <div>{{ chunk.chunk_data.rssi_message }}</div>
          </div>
        </div>

        <div v-if="meterDetail.breaker_status" class="tw-flex tw-mt-2">
          <span class="tw-font-bold tw-mr-2">Breaker status </span>
          <span>{{ meterDetail.breaker_status }}</span>
        </div>
        <div class="tw-flex tw-mt-2">
          <span class="tw-font-bold tw-mr-2">Date activated </span>
          <span>{{ formatDate(meterDetail.date_activated) }}</span>
        </div>
        <div class="tw-flex tw-mt-2">
          <span class="tw-font-bold tw-mr-2">Last successful connection</span>
          <span>{{ formatDate(meterDetail.last_successful_communication) }}</span>
        </div>
        <div class="tw-flex tw-mt-2">
          <span class="tw-font-bold tw-mr-2">RSSI last value</span>
          <span :class="getRssiClass(meterDetail.rssi_last_value)">
            {{ meterDetail.rssi_last_value }}
          </span>
        </div>
        <div class="tw-flex tw-mt-2">
          <span class="tw-font-bold tw-mr-2">RSSI last connection</span>
          <span>{{ formatDate(meterDetail.rssi_last_record_time) }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, computed } from 'vue'
import { useQuery, useMutation, useQueryClient } from '@tanstack/vue-query'
import fetchData from '@/util/fetch'
import { formatDate, notifications } from '@/util'

const props = defineProps({
  serialNumber: {
    type: String,
    required: true,
  },
})

const getRssiClass = (rssi) => {
  const value = Number(rssi)
  if (value >= -55) return 'tw-text-green-600'
  if (value > -70) return 'tw-text-amber-600'
  return 'tw-text-red-600'
}

const queryClient = useQueryClient()

const {
  data: isAlive,
  isLoading,
  isFetching,
  refetch,
} = useQuery({
  queryKey: computed(() => ['healthCheck', props.serialNumber]),
  queryFn: async () => {
    const result = await fetchData(`/eleena/api/v1/meters/${props.serialNumber}/ping`)
    if (result.status !== 'OK' || !result) {
      notifications.addNotification({
        message: `Failed to get meter status: ${result.statusText || 'Unknown error'}`,
        type: 'danger',
      })
      throw new Error('Failed to fetch meter status')
    }
    return result?.data?.alive || false
  },
  enabled: false,
  refetchOnWindowFocus: false,
})

const { data: meterDetail, isLoading: meterLoading } = useQuery({
  queryKey: computed(() => ['meterDetail', props.serialNumber]),
  queryFn: async () => {
    const result = await fetchData(`/eleena/api/v1/meters/${props.serialNumber}`)
    if (result.status !== 'OK' || !result) {
      notifications.addNotification({
        message: `Failed to get the meter: ${result.statusText}`,
        type: 'danger',
      })
      throw new Error('Failed to fetch meter data')
    }
    return result?.data
  },
  refetchOnWindowFocus: false,
})

const receivedChunks = ref([])

const { mutate: pollMeter, isLoading: isPolling } = useMutation({
  mutationFn: async () => {
    receivedChunks.value = []

    const response = await fetch(`/eleena/api/v1/meters/${props.serialNumber}/poll`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
    })

    if (!response.body) {
      throw new Error('No response body received')
    }

    const decoder = new TextDecoder('utf-8')
    const reader = response.body.getReader()
    let buffer = ''

    while (true) {
      const { value, done } = await reader.read()
      if (done) break

      buffer += decoder.decode(value, { stream: true })
      const parts = buffer.split('\n')
      buffer = parts.pop()

      for (const part of parts) {
        if (part.trim()) {
          const chunk = JSON.parse(part)
          receivedChunks.value = [...receivedChunks.value, chunk]
          notifications.addNotification({
            message: `Received chunk ${chunk.chunk_number}: ${chunk.chunk_text}`,
            type: 'info',
          })
        }
      }
    }
  },
  onSuccess: () => {
    queryClient.invalidateQueries({ queryKey: ['meterDetail'] })
    notifications.addNotification({ message: 'Polling completed.', type: 'success' })
  },
  onError: (error) => {
    notifications.addNotification({
      message: `Polling failed: ${error.message}`,
      type: 'danger',
    })
  },
})

const action = computed(() => {
  if (meterDetail.breaker_status === 'On') return 'deactivate'
  return 'activate'
})

const breakerActivation = async () =>
  fetchData(`/eleena/api/v1/meters/${props.serialNumber}/${action}`, {
    method: 'POST',
  })

const { mutate: toggleBreaker, isLoading: isActivationInProgress } = useMutation({
  mutationFn: breakerActivation,
  onSuccess: () => {
    queryClient.invalidateQueries({ queryKey: ['meterDetail'] })
    notifications.addNotification({
      message: `Breaker has been ${action}d`,
      type: 'success',
    })
  },
  onError: (e) => {
    notifications.addNotification({
      message: `Failed to ${action} the breaker, ${e.message}`,
      type: 'danger',
    })
    throw new Error('Failed to change breaker status')
  },
})
</script>
